<template lang="pug">
fi-modal.customer-communication-view-modal(
  ref     = 'modal'
  v-model = 'showModal'
  :title  = '$t("title")'
  size    = 'lg'
  lazy
  @shown  = 'modalShown'
  @hide   = 'setAsRead'
)
  fi-data-field(
    :label     = '$t("channel")'
    label-cols = 2
  ) {{ channel.human }}

  fi-data-field(
    :label     = '$t("priority")'
    label-cols = 2
  ) {{message.priority}}

  fi-data-field(
    :label     = '$t("sent")'
    label-cols = 2
  ) {{ $t('common:formatDatetime', { value: message.createdAt } ) }}

  fi-data-field(
    v-if       = 'reassigning'
    :label     = '$t("sentBy")'
    label-cols = 2
  )
    fi-multiselect(
      v-model          = 'selectedCustomerModel'
      :options         = 'systemCustomersForSelect'
      :search-callback = 'findSystemCustomers'
      :loading         = '$vueLoading.isLoading("customers:system:fetch")'
    )

  fi-data-field(
    v-else
    :label     = '$t("sentBy")'
    label-cols = 2
  ) {{ sentBy }}

  fi-data-field(
    :label     = '$t("sentTo")'
    label-cols = 2
  ) {{ sentTo }}

  fi-data-field(
    :label     = '$t("subject")'
    label-cols = 2
  ) {{ message.subject }}

  fi-data-field(
    :label     = '$t("text")'
    label-cols = 2
  ): span(v-html = 'sanitizedText')

  fi-data-field(
    :label     = '$t("attachments")'
    label-cols = 2
  )
    .font-weight-bold(v-for = 'document in message.documentEntities')
      button.btn.btn-link(@click.prevent = 'downloadResource(document)') {{ document.fileName || document.s3File}}
  template(
    #modal-footer
    v-if = 'reassigning'
  )
    button.btn.btn-primary(@click.prevent = 'reassignMessage') {{ $t('common:save') }}
    button.btn.btn-secondary(@click.prevent = 'setReassignMode(false)') {{ $t('common:cancel') }}
  template(
    #modal-footer
    v-else
  )
    button.btn.btn-primary(
      v-if = 'reassignAvailable'
      @click.prevent = 'setReassignMode(true)'
    ) {{ $t('reassign') }}
    button.btn.btn-primary(
      v-else
      @click.prevent = 'replyMessage'
    ) {{ $t('reply') }}
    button.btn.btn-secondary(@click.prevent = '$refs.modal.hide()') {{ $t('common:close') }}
</template>


<script>
import FiMultiselect from '@/components/FiMultiselect'
import FiSelect from '@/components/FiSelect'
import { mapActions, mapGetters, mapState } from 'vuex'
import sanitizeHtml from 'sanitize-html'
import FiDataField from '@/components/FiDataField'
import FiModal from '@/components/FiModal'

export default {
  name: 'customer-communication-view-modal',

  components: {
    FiMultiselect,
    FiSelect,
    FiDataField,
    FiModal
  },

  props: {
    value: {
      type: Boolean,
      required: true
    },
    message: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  data: () => ({
    selectedCustomer: null,
    reassigning: false,
    messageData: {
      newOwner: ''
    }
  }),

  computed: {
    ...mapGetters('classifiers', ['classifierById', 'optionsFromManagers']),
    ...mapGetters('customers', ['systemCustomersForSelect']),
    ...mapState('settings', ['settings']),
    ...mapState('auth', ['user']),
    selectedCustomerModel: {
      get () {
        return this.selectedCustomer
      },
      set (selected) {
        this.selectedCustomer = selected
        this.messageData.newOwner = (selected && selected.value) || ''
      }
    },
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    sanitizedText () {
      return sanitizeHtml(this.message.text, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(['u'])
      })
    },
    channel () {
      return this.classifierById('documentDeliveryChannels', this.message.channelId)
    },
    getPriority () {
      return this.classifierById('priorities', this.message.priorityId)
    },
    reassignAvailable () {
      return this.user.id === Number(this.settings.unmatchedEmailResponsible) && !this.message?.from
    },
    sentTo () {
      if (this.channel.code === 'email') {
        return this.message.to?.email
      }
      return this.message.to?.phoneNumber
    },
    sentBy () {
      return this.message.from?.email
    },
    readerStatus () {
      const { direction, inboxReaderStatusId, outboxReaderStatusId } = this.message
      const readerStatus = direction === 'in' ? inboxReaderStatusId : outboxReaderStatusId
      return this.classifierById('readerStatus', readerStatus)
    }
  },

  methods: {
    ...mapActions('customers', ['loadCustomerResourceFile', 'findSystemCustomers']),
    resetData () {
      Object.assign(this.$data, this.$options.data.apply(this))
    },
    downloadResource (document) {
      this.loadCustomerResourceFile({ id: document.id })
    },
    modalShown () {
      this.resetData()
    },
    replyMessage () {
      this.$emit('reply', this.message)
      this.$refs.modal.hide()
    },
    setReassignMode (state = !this.reassigning) {
      this.reassigning = state
      this.messageData.newOwner = this.message.createdBy
    },
    reassignMessage () {
      this.$emit('reassign', { message: this.message, newOwner: this.messageData.newOwner })
      this.$refs.modal.hide()
    },
    setAsRead () {
      if (this.readerStatus.code === 'unread') {
        this.$emit('read', [this.message.id])
      }
    }
  }
}
</script>


<i18n>
en:
  title:       "View message"
  channel:     "Channel"
  priority:    "Priority"
  sent:        "Sent"
  sentBy:      "Sent by"
  sentTo:      "Sent to"
  subject:     "Subject"
  text:        "Text"
  attachments: "Attachments"
  reply:       "Reply"
  reassign:    "Reassign"
et:
  title:       "View message"
  channel:     "Channel"
  priority:    "Priority"
  sent:        "Sent"
  sentBy:      "Sent by"
  sentTo:      "Sent to"
  subject:     "Subject"
  text:        "Text"
  attachments: "Attachments"
  reply:       "Reply"
  reassign:    "Reassign"
ru:
  title:       "View message"
  channel:     "Channel"
  priority:    "Priority"
  sent:        "Sent"
  sentBy:      "Sent by"
  sentTo:      "Sent to"
  subject:     "Subject"
  text:        "Text"
  attachments: "Attachments"
  reply:       "Reply"
  reassign:    "Reassign"
</i18n>
