<template lang="pug">
.customer-communication-view
  .row
    .col-lg
      customer-comments-view(
        v-if            = '!disableNotes'
        :comments-state = 'commentsState'
      )
  .card-deck
      customer-document-list(
        :store-module           = 'storeModule'
        :resources-state        = 'resourcesState'
        :resources-pages-state  = 'resourcesPagesState'
        :resource-action        = 'createResourceMethod'
        :customer-history-state = 'customerHistoryState'
      )
      customer-communication(
        :messages-state       = 'messagesState'
        :messages-pages-state = 'messagesPagesState'
        :customer-state       = 'customerState'
      )
</template>


<script>
import CustomerCommentsView from '@/views/customers/CustomerCommentsView'
import CustomerCommunication from './CustomerCommunication'
import CustomerDocumentList from './CustomerDocumentList'
import { mapActions } from 'vuex'

export default {
  name: 'customer-communication-view',

  components: {
    CustomerCommentsView,
    CustomerCommunication,
    CustomerDocumentList
  },

  provide () {
    return {
      storeModule: this.storeModule,
      commentableType: this.commentableType
    }
  },

  props: {
    customerState: {
      type: String,
      default: 'customer'
    },
    commentsState: {
      type: String,
      default: 'customerComments'
    },
    messagesState: {
      type: String,
      default: 'customerMessages'
    },
    messagesPagesState: {
      type: String,
      default: 'customerMessagesPages'
    },
    storeModule: {
      type: String,
      default: 'customers'
    },
    resourcesState: {
      type: String,
      default: 'customerResources'
    },
    resourcesPagesState: {
      type: String,
      default: 'customerResourcesPages'
    },
    resourceAction: {
      type: Function,
      default: null
    },
    commentableType: {
      type: String,
      default: 'CUSTOMER'
    },
    disableNotes: {
      type: Boolean,
      default: false
    },
    customerHistoryState: {
      type: String,
      default: 'customerHistory'
    }
  },

  computed: {
    createResourceMethod () {
      if (this.resourceAction) return this.resourceAction
      return this.createCustomerResource
    }
  },

  methods: {
    ...mapActions('customers', ['createCustomerResource'])
  }
}
</script>
