<template lang="pug">
tr.customer-notes-list-item
  td
    fi-switch.ml-auto(
      v-model   = 'computedStatus'
      :disabled = 'saving'
      on-text   = 'Active'
      off-text  = 'Inactive'
    )
  td.text-center {{ category.human }}
  td.text-center.text-nowrap
    router-link(:to = '{ name: "Customer", params: { id: comment.createdBy } }') {{ comment.createdByName }}
    br
    small.text-muted.mr-1 {{ $t('common:formatDatetime', { value: comment.createdAt }) }}
  td
    router-link(:to  = '`${$route.matched[1].path}/${comment.commentableId}/overview`') {{ comment.contractAgreementNumber }}
  td.w-25: .comment-text {{ comment.comment }}
  td.text-nowrap.text-right
    button.btn.btn-link.py-0.btn-sm(@click.prevent = 'editing = true'): i.far.fa-edit.fa-lg

    i.fa.fa-spinner.fa-pulse(v-if = 'removing')
    button.btn.btn-link.py-0.btn-sm.text-danger(
      v-else
      :disabled    = 'removing'
      v-fi-confirm = '{ ok: remove, message: $t("removeConfirm") }'
      ): i.far.fa-trash-alt.fa-lg
    customer-comment-modal(
      v-model           = 'editing'
      :comment          = 'comment'
      :commentable-type = 'commentableType'
      :store-module     = 'storeModule'
    )
</template>


<script>
import FiDataField from '@/components/FiDataField'
import { mapActions, mapGetters } from 'vuex'
import FiSwitch from '@/components/FiSwitch'
import CustomerCommentModal from './CustomerCommentModal'
import { Comment } from '@/models'

export default {
  name: 'customer-notes-list-item',

  components: {
    FiDataField,
    FiSwitch,
    CustomerCommentModal
  },

  inject: ['storeModule', 'commentableType'],

  props: {
    comment: {
      type: Object,
      required: true
    }
  },

  i18nOptions: {},

  data () {
    return {
      editing: false
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierById', 'managerById', 'commentActionIcon']),
    saving () {
      return this.$vueLoading.isLoading(`${this.storeModule}:comment:${this.comment.id}:save`)
    },
    removing () {
      return this.$vueLoading.isLoading(`${this.storeModule}:comment:${this.comment.id}:remove`)
    },
    category () {
      return this.classifierById('commentCategory', this.comment.categoryId)
    },
    computedStatus: {
      get () {
        return this.comment.active
      },
      set (status) {
        if (!this.saving) this.updateComment({ commentId: this.comment.id, commentData: new Comment({ ...this.comment, active: status }) })
      }
    }
  },

  methods: {
    ...mapActions({
      updateComment (dispatch, payload) {
        return dispatch(`${this.storeModule}/updateComment`, payload)
      },
      removeComment (dispatch, payload) {
        return dispatch(`${this.storeModule}/removeComment`, payload)
      }
    }),
    async remove ({ done }) {
      await this.removeComment({ id: this.comment.id })
      done()
    }
  }
}
</script>


<style scoped lang="scss">
  .comment-text {
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 21px;     /* fallback */
    max-height: 42px;      /* fallback */
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
  }
</style>


<i18n>
en:
  removeConfirm: "Are you sure you want to delete this note?"
et:
  removeConfirm: "Are you sure you want to delete this note?"
ru:
  removeConfirm: "Are you sure you want to delete this note?"
</i18n>
