<template lang = 'pug'>
.messages-list
  .form-row
    .form-group.col-6
      .input-group
        .input-group-prepend: span.input-group-text: i.fa.fa-search
        input.form-control.form-control-sm(
          v-model.trim = 'searchParams.subject'
          :placeholder = '$t("subjectPh")'
        )
    .form-group.col-6
      .input-group
        .input-group-prepend: span.input-group-text: i.fa.fa-calendar
        fi-datepicker(
          v-model      = 'searchParams.createdAt'
          :placeholder = '$t("createdAt")'
          sm
        )
    .form-group.col
      .input-group
        .input-group-prepend: span.input-group-text: i.fa.fa-envelope
        fi-select(
          v-model.number = 'searchParams.channelId'
          :options       = 'optionsFromClassifier("documentDeliveryChannels")'
          :placeholder   = '$t("channelPh")'
          sm
        )
    .form-group.col(v-if = 'direction === "out"')
      .input-group
        .input-group-prepend: span.input-group-text: i.fa.fa-user
        fi-select(
          v-model.number = 'searchParams.createdBy'
          :options       = 'optionsFromManagers'
          :placeholder   = '$t("managerPh")'
          sm
        )
    .form-group.col
      .input-group
        .input-group-prepend: span.input-group-text: i.fa.fa-flag
        fi-select(
          v-model.number = 'searchParams.priorityId'
          :options       = 'optionsFromClassifier("priorities")'
          :placeholder   = '$t("priorityPh")'
          sm
        )
  .form-row(v-if = 'direction === "in"')
    button.btn.btn-link.py-0(
      :class = '{ "text-secondary": searchParams.inboxReaderStatusId === read.id }'
      @click = 'searchParams.inboxReaderStatusId = read.id'
    ) {{ $t('read') }}
    | /
    button.btn.btn-link.py-0(
      :class = '{ "text-secondary": searchParams.inboxReaderStatusId === unread.id }'
      @click = 'searchParams.inboxReaderStatusId = unread.id'
    ) {{ $t('unread') }}
    | /
    button.btn.btn-link.py-0(
      :class = '{ "text-secondary": searchParams.inboxReaderStatusId === undefined }'
      @click = 'searchParams.inboxReaderStatusId = undefined'
    ) {{ $t('all') }}
  fi-table(
    :fields      = 'fields'
    :items       = 'messages'
    loader       = 'customer:messages:fetch'
    :empty-label = '$t("notFound")'
    :group-by    = 'messagesGrouping'
    :sort.sync   = 'pagination.sort'
    responsive
  )
    template(#selectAll)
      b-check(
        :checked       = 'allSelected'
        :indeterminate = 'indeterminate'
        :disabled      = '!messages.length'
        @change        = 'toggleSelectAll'
      )
    template(#custom-row)
      tr.animated.fadeIn(v-if = 'selectedItems.length')
        td(:colspan = 'fields.length')
          .btn-toolbar
            .btn-group
              button.btn.btn-outline-primary(
                :title = '$t("markRead")'
                @click = 'markRead()'
              ): i.far.fa-envelope-open
              button.btn.btn-outline-primary(
                :title = '$t("markUnread")'
                @click = 'markUnread()'
              ): i.far.fa-envelope
    template(#default = '{ items }')
      customer-communication-item(
        v-for     = 'message in items'
        :key      = 'message.id'
        v-model   = 'selectedItems'
        :message  = 'message'
        @reply    = 'reply'
        @reassign = 'reassign'
        @read     = 'markRead'
      )
  fi-pagination(
    v-if    = 'messagesPages > 1'
    v-model = 'pagination.page'
    :pages  = 'messagesPages'
  )
</template>


<script>
import FiDatepicker from '@/components/FiDatepicker'
import FiPagination from '@/components/FiPagination'
import FiSelect from '@/components/FiSelect'
import FiTable from '@/components/FiTable'
import CustomerCommunicationItem from '@/views/customers/CustomerCommunicationItem'
import CustomerCommunicationNewModal from '@/views/customers/CustomerCommunicationNewModal'
import debounce from 'lodash/debounce'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'customer-communication-list',

  components: {
    CustomerCommunicationNewModal,
    FiPagination,
    CustomerCommunicationItem,
    FiTable,
    FiSelect,
    FiDatepicker
  },

  props: {
    storeModule: {
      type: String,
      required: true
    },
    messagesState: {
      type: String,
      required: true
    },
    messagesPagesState: {
      type: String,
      required: true
    },
    direction: {
      type: String,
      required: true
    }
  },

  i18nOptions: {},

  data: (vm) => ({
    selectedItems: [],
    searchParams: {
      direction: vm.direction,
      channelId: '',
      subject: '',
      createdAt: '',
      createdBy: '',
      priorityId: '',
      inboxReaderStatusId: undefined
    },
    pagination: {
      sort: 'createdAt,desc',
      page: 0,
      size: 25
    }
  }),

  computed: {
    ...mapGetters('classifiers', ['optionsFromManagers', 'optionsFromClassifier', 'classifierByName']),
    ...mapState({
      messages (state) {
        return state[this.storeModule][this.messagesState]
      },
      messagesPages (state) {
        return state[this.storeModule][this.messagesPagesState]
      }
    }),
    fields () {
      return [
        {
          key: 'selectAll'
        },
        {
          key: 'subject',
          label: this.$t('subject'),
          sort: 'subject'
        },
        {
          key: 'text',
          label: this.$t('text')
        },
        {
          key: 'author',
          label: this.$t('author'),
          sort: 'createdBy'
        },
        {
          key: 'createdAt',
          label: this.$t('createdTime'),
          sort: 'createdAt'
        },
        {
          key: 'priority',
          label: this.$t('priority'),
          sort: 'priorityId'
        }
      ]
    },
    read () {
      return this.classifierByName('readerStatus', 'read') ?? {}
    },
    unread () {
      return this.classifierByName('readerStatus', 'unread') ?? {}
    },
    allSelected () {
      return this.selectedItems?.length && this.selectedItems?.length === this.messageIds?.length
    },
    messageIds () {
      return this.messages.map(({ id }) => id) || []
    },
    indeterminate () {
      const { length } = this.selectedItems
      return Boolean(length) && (length < this.messageIds?.length)
    }
  },

  watch: {
    searchParams: {
      handler () {
        this.pagination.page = 0
        this.search()
      },
      deep: true
    },
    pagination: {
      handler: 'search',
      deep: true
    }
  },

  created () {
    this.loadManagers()
    this.loadMessages({ filters: this.searchParams, ...this.pagination })
  },

  methods: {
    ...mapActions('classifiers', ['loadManagers']),
    ...mapActions({
      loadMessages (dispatch, payload) {
        return dispatch(this.storeModule + '/loadMessages', payload)
      },
      setMessageRead: 'messages/markMessageAsRead',
      setMessageUnread: 'messages/markMessageAsUnread'
    }),
    messagesGrouping ({ createdAt }) {
      return this.$t('common:formatDate', { value: createdAt })
    },
    toggleSelectAll (state) {
      if (state) {
        this.selectedItems = this.messageIds
      } else {
        this.selectedItems = []
      }
    },
    reply (replyMessage) {
      this.$emit('reply', replyMessage)
    },
    async markRead (messageIds = this.selectedItems) {
      await this.setMessageRead({ messageIds })
      this.search()
    },
    async markUnread (messageIds = this.selectedItems) {
      await this.setMessageUnread({ messageIds })
      this.search()
    },
    search: debounce(
      function () {
        this.selectedItems = []
        this.loadMessages({ filters: this.searchParams, ...this.pagination })
      }, 250
    ),
    async reassign ({ message, newOwner }) {
      await this.messageReassign({ messageId: message.id, customerId: newOwner })
      this.search()
    }
  }

}
</script>


<i18n>
en:
  subject:     "Subject"
  text:        "Text"
  author:      "Sent by"
  createdAt:   "Date"
  createdTime: "Time"
  priority:    "Priority"
  notFound:    "Communications not found"
  subjectPh:   "Search by subject"
  managerPh:   "Message from"
  channelPh:   "Select channel"
  priorityPh:  "Select priority"
  read:        "Read"
  unread:      "Unread"
  all:         "All"
et:
  subject:     "Subject"
  text:        "Text"
  author:      "Sent by"
  createdAt:   "Date"
  createdTime: "Time"
  priority:    "Priority"
  notFound:    "Communications not found"
  sendMessage: "Send message"
  subjectPh:   "Search by subject"
  managerPh:   "Message from"
  channelPh:   "Select channel"
  priorityPh:  "Select priority"
  read:        "Read"
  unread:      "Unread"
  all:         "All"
ru:
  subject:     "Subject"
  text:        "Text"
  author:      "Sent by"
  createdAt:   "Date"
  createdTime: "Time"
  priority:    "Priority"
  notFound:    "Communications not found"
  sendMessage: "Send message"
  subjectPh:   "Search by subject"
  managerPh:   "Message from"
  channelPh:   "Select channel"
  priorityPh:  "Select priority"
  read:        "Read"
  unread:      "Unread"
  all:         "All"
</i18n>
