<template lang = "pug">
.card.customer-document-list
  .card-header
    .row.align-items-center
      .col {{ $t('title') }}
      .col-auto
        button.btn.btn-link.p-0(
          v-if           = '!newForm'
          @click.prevent = 'newForm = true'
        ) {{ $t('newLink') }}
  .card-body
    .row
      .form-group.col-4
        .input-group
          .input-group-prepend
            span.input-group-text
              i.fa.fa-file-alt
          fi-select(
            v-model.number = 'pagination.documentCategoryId'
            :options       = 'categories'
            :placeholder   = '$t("categoryPh")'
            sm
          )
    fi-table(
      :fields     = 'fields'
      :items      = 'resources'
      loader      = 'customer:resources:fetch'
      :emptyLabel = '$t("notFound")'
      responsive
    )
      template(#default = '{ items }')
        customer-document-list-item(
          v-for         = 'resource in items'
          :key          = 'resource.id'
          :resource     = 'resource'
          :store-module = 'storeModule'
          :categories   = 'categories'
          :contracts    = 'contracts'
        )
  fi-pagination(
    v-if    = 'resourcesPages > 1'
    :pages  = 'resourcesPages'
    v-model = 'pagination.page'
  )
  customer-document-form(
    v-model          = 'newForm'
    key              = 'createNewDocument'
    :categories      = 'categories'
    :resource-action = 'resourceAction'
    :contracts       = 'contracts'
  )
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiPagination from '@/components/FiPagination'
import FiSelect from '@/components/FiSelect'
import FiTable from '@/components/FiTable'
import { mapActions, mapGetters, mapState } from 'vuex'
import CustomerDocumentForm from './CustomerDocumentForm'
import CustomerDocumentListItem from './CustomerDocumentListItem'

export default {
  name: 'customer-document-list',

  components: {
    FiTable,
    FiFormField,
    FiSelect,
    FiPagination,
    CustomerDocumentForm,
    CustomerDocumentListItem
  },

  inject: ['storeModule'],

  props: {
    resourcesState: {
      type: String,
      required: true
    },
    resourcesPagesState: {
      type: String,
      required: true
    },
    resourceAction: {
      type: Function,
      required: true
    },
    customerHistoryState: {
      type: String,
      required: true
    }
  },

  i18nOptions: {},

  data () {
    return {
      newForm: false,
      pagination: {
        documentCategoryId: '',
        page: 0,
        size: 10,
        sort: 'createdAt,desc'
      }
    }
  },

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierByName']),
    ...mapState({
      resources (state) {
        return state[this.storeModule][this.resourcesState]
      },
      resourcesPages (state) {
        return state[this.storeModule][this.resourcesPagesState]
      },
      customerHistory (state) {
        return state[this.storeModule][this.customerHistoryState]
      }
    }),
    categories () {
      return this.optionsFromClassifier('DocumentCategoryType')
    },
    contracts () {
      if (!this.customerHistory) return []
      const { contracts = [], creditApplications = [] } = this.customerHistory
      return [
        {
          documentRelation: this.classifierByName('documentRelation', 'CREDIT_APPLICATION')?.human,
          items: creditApplications.map(({ id }) => ({
            documentRelationEntityId: id,
            documentRelationEntityContractNumber: id,
            documentRelationCode: 'CREDIT_APPLICATION'
          }))
        },
        {
          documentRelation: this.classifierByName('documentRelation', 'LOAN')?.human,
          items: contracts.filter(({ loanId }) => loanId)
            .map(({ loanId, agreementNumber }) => ({
              documentRelationEntityId: loanId,
              documentRelationEntityContractNumber: agreementNumber,
              documentRelationCode: 'LOAN'
            }))
        },
        {
          documentRelation: this.classifierByName('documentRelation', 'DEPOSIT_CONTRACT')?.human,
          items: contracts.filter(({ contractNumber }) => contractNumber)
            .map(({ id, contractNumber }) => ({
              documentRelationEntityId: id,
              documentRelationEntityContractNumber: contractNumber,
              documentRelationCode: 'DEPOSIT_CONTRACT'
            }))
        }
      ]
    },
    fields () {
      return [
        {
          label: this.$t('file')
        },
        {
          label: this.$t('contract')
        },
        {
          label: this.$t('category')
        },
        {
          label: this.$t('comment')
        },
        {
          label: this.$t('date')
        },
        {
          label: this.$t('visibleForPortal')
        },
        {}
      ]
    }
  },

  watch: {
    pagination: {
      handler () {
        this.search()
      },
      deep: true
    }
  },

  created () {
    this.search()
  },

  methods: {
    ...mapActions({
      loadResources (dispatch, payload) {
        return dispatch(this.storeModule + '/loadResources', payload)
      }
    }),
    search () {
      this.loadResources({ filters: { ...this.pagination } })
    }
  }
}
</script>


<i18n>
en:
  title:            "Documents"
  newLink:          "+ New document"
  categoryPh:       "Filter by category"
  file:             "Filename"
  contract:         "Contract"
  category:         "Category"
  comment:          "Comment"
  date:             "Date"
  visibleForPortal: "Visible to customer"
  notFound:         "Documents not found"
et:
  title:            "Documents"
  newLink:          "+ New document"
  categoryPh:       "Filter by category"
  file:             "Filename"
  contract:         "Contract"
  category:         "Category"
  comment:          "Comment"
  date:             "Date"
  visibleForPortal: "Visible to customer"
  notFound:         "Documents not found"
ru:
  title:            "Documents"
  newLink:          "+ New document"
  categoryPh:       "Filter by category"
  file:             "Filename"
  contract:         "Contract"
  category:         "Category"
  comment:          "Comment"
  date:             "Date"
  visibleForPortal: "Visible to customer"
  notFound:         "Documents not found"
</i18n>
