<template lang = "pug">
b-modal.customer-note-list-modal(
  ref               = 'modal'
  v-model           = 'showModal'
  :title            = '$t("title")'
  size              = 'xl'
  header-bg-variant = 'warning'
  lazy
  hide-footer
)
  .customer-note-list.animated.fadeIn
    .card.border-warning(v-for  = 'item in comments.slice(3)'
                        @click = '$router.push({ name: "CustomerCommunication", params: { id: item.customerId } })')
      .card-body
        .row
          .col
            p.comment {{ item.comment }}
          .col-auto
            small.text-muted.mr-1.fa-pull-right
              router-link(:to = '{ name: "Customer", params: { id: item.createdBy } }') {{ item.createdByName }}
              br
              | {{ $t('common:formatDatetime', { value: item.createdAt }) }}
</template>


<script>

import FiModal from '@/components/FiModal'

export default {
  name: 'customer-note-list-modal',

  components: {
    FiModal
  },

  props: {
    comments: {
      type: Array,
      default: null
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    showModal: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>


<i18n>
en:
  title:    "All related comments"
  noteNew:  "New note"
  taskNew:  "New task"
  noteEdit: "Edit note"
  taskEdit: "Edit task"
  status:   "Status"
  category: "Category"
  setTask:  "Add task"
  assignTo: "Assign to"
  dueDate:  "Due date"
  actions:  "Actions"
  message:  "Message"
  result:   "Result"
et:
  title:    "All related comments"
  noteNew:  "New note"
  taskNew:  "New task"
  noteEdit: "Edit note"
  taskEdit: "Edit task"
  status:   "Status"
  category: "Category"
  setTask:  "Add task"
  assignTo: "Assign to"
  dueDate:  "Due date"
  actions:  "Actions"
  message:  "Message"
  result:   "Result"
ru:
  title:    "All related comments"
  noteNew:  "New note"
  taskNew:  "New task"
  noteEdit: "Edit note"
  taskEdit: "Edit task"
  status:   "Status"
  category: "Category"
  setTask:  "Add task"
  assignTo: "Assign to"
  dueDate:  "Due date"
  actions:  "Actions"
  message:  "Message"
  result:   "Result"
</i18n>

<style scoped lang="scss">
p.comment {
  white-space: pre-line;
  margin-bottom: 0;
}
.card {
  cursor: pointer;
}
</style>
