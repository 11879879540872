<template lang="pug">
div
  .customer-note-list.animated.fadeIn
    .card.border-warning(v-for  = 'item in sortComments.slice(0, 3)' :class="{ 'm-b-0': comments && comments.length > 3 }"
                        @click = '$router.push({ name: "CustomerCommunication", params: { id: item.customerId } })')
      .card-body
        .row
          .col
            p.comment {{ item.comment }}
          .col-auto
            small.text-muted.mr-1.fa-pull-right
              router-link(:to = '{ name: "Customer", params: { id: item.createdBy } }') {{ item.createdByName }}
              br
              | {{ $t('common:formatDatetime', { value: item.createdAt }) }}
  div.text-center.nav-link.m-b-15(v-if = 'comments && comments.length > 3' @click.prevent = 'showModal = true'): i.fa.fa-ellipsis-h.fa-lg
  customer-note-list-modal(
    v-if       = 'showModal'
    v-model    = 'showModal'
    :comments  = 'sortComments'
  )
</template>


<script>

import CustomerNoteListModal from './CustomerNoteListModal'

export default {
  name: 'customer-note-list',

  components: {
    CustomerNoteListModal
  },


  props: {
    comments: {
      type: Array,
      required: true
    }
  },
  data: () => ({
    showModal: false
  }),
  computed: {
    sortComments () {
      const list = JSON.parse(JSON.stringify(this.comments))
      const sortList = list.sort(function (a, b) {
        const nameA = a.createdAt
        const nameB = b.createdAt
        if (nameA > nameB) return -1
        if (nameA < nameB) return 1
        return 0
      })
      return sortList
    }
  }
}
</script>


<style scoped lang="scss">
p.comment {
  white-space: pre-line;
  margin-bottom: 0;
}
.card {
  cursor: pointer;
}
.m-b-0:last-child {
  margin-bottom: 0 !important;
}
.m-b-15 {
  margin-bottom: 15px
}
</style>
