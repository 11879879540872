<template lang="pug">
.customer-notes-list
  button.btn.btn-link.fa-pull-right.pb-2(@click.prevent = 'newForm = true') {{ $t('newNote') }}
  | {{ $t("filterBy") }}
  button.btn.btn-link(:class = '{ "text-secondary": filter.active === true }'
                      @click = 'filter.active = true') {{ $t('active') }}
  | /
  button.btn.btn-link(:class = '{ "text-secondary": filter.active === false }'
                      @click = 'filter.active = false') {{ $t('inactive') }}
  | /
  button.btn.btn-link(:class = '{ "text-secondary": filter.active === null }'
                      @click = 'filter.active = null') {{ $t('all') }}
  fi-table(v-slot      = '{ items }'
           :fields     = 'notesFields'
           :items      = 'notes'
           :loader     = 'loader'
           :emptyLabel = '$t("notFound")'
           responsive)
    customer-notes-list-item(v-for    = 'comment in items'
                             :key     = 'comment.id'
                             :comment = 'comment')
    fi-pagination(v-if    = 'notesPages > 1'
                  :pages  = 'notesPages'
                  v-model = 'pagination.page')
  customer-comment-modal(v-model = 'newForm'
                         :commentable-type = 'commentableType'
                         :store-module     = 'storeModule')
</template>


<script>
import FiPagination from '@/components/FiPagination'
import CustomerCommentModal from '@/views/customers/CustomerCommentModal'
import CustomerNotesListItem from '@/views/customers/CustomerNotesListItem'
import FiTable from '@/components/FiTable'

export default {
  name: 'customer-notes-list',

  components: {
    FiPagination,
    CustomerCommentModal,
    CustomerNotesListItem,
    FiTable
  },

  inject: ['commentableType', 'storeModule'],

  props: {
    notes: {
      type: Array,
      required: true
    },
    notesPages: {
      type: Number,
      default: 0
    },
    loadNotes: {
      type: Function,
      required: true
    },
    loader: {
      type: String,
      default: ''
    }
  },

  i18nOptions: {},

  data: () => ({
    newForm: false,
    filter: {
      active: true
    },
    pagination: {
      page: 0,
      size: 10
    }
  }),

  computed: {
    notesFields () {
      return [
        {
          key: 'status',
          label: this.$t('status')
        },
        {
          key: 'category',
          label: this.$t('category'),
          class: 'text-center'
        },
        {
          key: 'createdBy',
          label: this.$t('createdBy'),
          class: 'text-center'
        },
        {
          key: 'agreementNumber',
          label: this.$t('contract'),
          class: 'text-center'
        },
        {
          key: 'comment',
          label: this.$t('note')
        },
        {}
      ]
    }
  },

  watch: {
    'pagination.page' () {
      this.search()
    },
    'filter.active' () {
      this.search()
    }
  },

  mounted () {
    this.search()
  },

  methods: {
    search () {
      this.loadNotes({ id: this.$route.params.id, filters: { ...this.pagination, ...this.filter } })
    }
  }
}
</script>


<i18n>
en:
  title:      "Notes"
  filterBy:   "Filter by"
  active:     "Active"
  inactive:   "Inactive"
  all:        "All"
  newNote:    "+ New note"
  notFound:   "Notes not found"
  status:     "Status"
  category:   "Category"
  createdBy:  "Created by"
  assignedTo: "Assigned to"
  actions:    "Actions"
  result:     "Result"
  contract:   "Contract"
  note:       "Note"
et:
  title:      "Notes"
  filterBy:   "Filter by"
  active:     "Active"
  inactive:   "Inactive"
  all:        "All"
  newNote:    "+ New note"
  notFound:   "Notes not found"
  status:     "Status"
  category:   "Category"
  createdBy:  "Created by"
  assignedTo: "Assigned to"
  actions:    "Actions"
  result:     "Result"
  contract:   "Contract"
  note:       "Note"
ru:
  title:      "Notes"
  filterBy:   "Filter by"
  active:     "Active"
  inactive:   "Inactive"
  all:        "All"
  newNote:    "+ New note"
  notFound:   "Notes not found"
  status:     "Status"
  category:   "Category"
  createdBy:  "Created by"
  assignedTo: "Assigned to"
  actions:    "Actions"
  result:     "Result"
  contract:   "Contract"
  note:       "Note"
</i18n>
