<template lang="pug">
tr.customer-communication-item(:class = '{ "font-weight-bold": unread }')
  td
    .custom-control.custom-checkbox
      input.custom-control-input(
        v-model = 'selected'
        :id     = '`communication-item-${message.id}`'
        type    = 'checkbox'
        :value  = 'message.id'
      )
      label.custom-control-label(:for = '`communication-item-${message.id}`')
  td
    i.fa(:class = 'channelIcon')
    button.btn.btn-link.p-0.ml-1(@click.prevent = 'showViewCommunicationModal = true') {{ message.subject || message.id }}
  td.customer-communication-item__text(v-html = 'sanitizedText')
  td {{ from.customerName }}
  td {{ $t('common:formatTime', { value: message.createdAt } ) }}
  td: .badge(:class = 'priorityClass') {{ classifierByName('priorities', message.priority.toLowerCase()).human }}

  customer-communication-view-modal(
    v-model  = 'showViewCommunicationModal'
    :message = 'message'
    v-on     = 'modalListeners'
  )
</template>


<script>
import { mapGetters } from 'vuex'
import CustomerCommunicationViewModal from './CustomerCommunicationViewModal'
import sanitizeHtml from 'sanitize-html'

export default {
  name: 'customer-communication-item',

  components: {
    CustomerCommunicationViewModal
  },

  props: {
    message: {
      type: Object,
      required: true
    },
    value: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      showViewCommunicationModal: false
    }
  },

  computed: {
    ...mapGetters('classifiers', ['classifierById', 'managerById', 'classifierByName']),
    unread () {
      const { direction, inboxReaderStatusId, outboxReaderStatusId } = this.message
      const readerStatus = direction === 'in' ? inboxReaderStatusId : outboxReaderStatusId
      return /unread/i.test(this.classifierById('readerStatus', readerStatus).name)
    },
    channelIcon () {
      return {
        'cil-phone fa-lg': this.classifierById('documentDeliveryChannels', this.message.channelId).name === 'call',
        'cil-speech': this.classifierById('documentDeliveryChannels', this.message.channelId).name === 'sms',
        'far fa-envelope': this.classifierById('documentDeliveryChannels', this.message.channelId).name === 'email'
      }
    },
    priorityClass () {
      return {
        'badge-danger': this.message.priority === 'HIGH',
        'badge-primary': this.message.priority === 'NORMAL',
        'badge-secondary': this.message.priority === 'LOW'
      }
    },
    selected: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    modalListeners () {
      const { reassign, reply, read } = this.$listeners
      return {
        reassign,
        reply,
        read
      }
    },
    sanitizedText () {
      return sanitizeHtml(this.message.text, {
        allowedTags: []
      })
    },
    from () {
      return this.message?.from ?? {}
    }
  }
}
</script>


<style scoped lang="scss">
i.fa { width: 1.5rem; }

.customer-communication-item {
  &__text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100px;
  }
}
</style>
