<template lang="pug">
tr.customer-document-list-items
  td.text-break
    i.far.fa-file-pdf.fa-lg.pr-3.d-inline
    a(href           = '#'
      @click.prevent = 'download') {{ resource.fileName || resource.s3File}}
      i.fa.fa-spinner.fa-pulse(v-if = 'downloading')
  td(v-if = '!short')
    router-link(
      v-if = 'isContractRelation'
      :to  = 'resourceRelationLink'
    ) {{ resource.documentRelationEntityContractNumber || resource.documentRelationEntityId }}
  td {{ classifierById('DocumentCategoryType', resource.documentCategoryId).human }}
  td {{ resource.comment }}
  td.text-nowrap(v-if = '!short') {{ $t('common:formatDatetime', { value: resource.createdAt }) }}
  td
    fi-switch(v-model = 'visibility')
  td.text-center
    button.btn.btn-link.ml-1.btn-sm(@click='editing = true')
      i.fa.fa-spinner.fa-pulse(v-if = 'saving')
      i.far.fa-edit.fa-lg(v-else)
    i.fa.fa-spinner.fa-pulse(v-if = 'removing')
    button.btn.btn-link.ml-1.btn-sm(
      v-else
      :disabled    = 'removing'
      v-fi-confirm = '{ ok: remove, message: $t("removeConfirm")}'
    )
      i.far.fa-trash-alt.fa-lg
  customer-document-form(
    v-model          = 'editing'
    :categories      = 'categories'
    :resource        = 'resource'
    :resource-action = 'updateResource'
    :contracts       = 'contracts'
    :short           = 'short'
  )
</template>


<script>
import CustomerDocumentForm from '@/views/customers/CustomerDocumentForm'
import { mapActions, mapGetters } from 'vuex'
import FiSwitch from '@/components/FiSwitch'
import { Resource } from '@/models'

const mapRelationRoute = {
  CREDIT_APPLICATION: 'Application',
  LOAN: 'Loan',
  DEPOSIT_CONTRACT: 'Deposit',
  FACTORING_CONTRACT: 'Factoring'
}

export default {
  name: 'customer-document-list-item',

  components: { FiSwitch, CustomerDocumentForm },

  props: {
    resource: {
      type: Object,
      required: true
    },
    storeModule: {
      type: String,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    contracts: {
      type: Array,
      default: () => []
    },
    short: {
      type: Boolean
    }
  },

  data: () => ({
    editing: false
  }),

  computed: {
    ...mapGetters('classifiers', ['classifierById', 'classifierByName']),
    removing () {
      return this.$vueLoading.isLoading(`resource:${this.resource.id}:remove`)
    },
    downloading () {
      return this.$vueLoading.isLoading(`resource:${this.resource.id}:fetch`)
    },
    saving () {
      return this.$vueLoading.isLoading(`resource:${this.resource.id}:save`)
    },
    visibility: {
      get () {
        return this.resource.visibleForPortal
      },
      set (visibility) {
        const { documentRelationEntityContractNumber, id, ...resource } = this.resource
        this.updateResource({ resourceId: id, resourceData: new Resource({ ...resource, visibleForPortal: visibility }) })
      }
    },
    resourceRelationLink () {
      const { documentRelationEntityId, documentRelationCode } = this.resource
      return {
        name: mapRelationRoute[documentRelationCode],
        params: { id: documentRelationEntityId }
      }
    },
    isContractRelation () {
      return !/message|customer/i.test(this.resource.documentRelationCode)
    }
  },

  methods: {
    ...mapActions('customers', ['loadCustomerResourceFile']),
    ...mapActions({
      async removeResource (dispatch, payload) {
        await dispatch(this.storeModule + '/removeResource', payload)
        this.emitChange()
      },
      async updateResource (dispatch, payload) {
        await dispatch(this.storeModule + '/updateResource', payload)
        this.emitChange()
      }
    }),
    async remove ({ done }) {
      await this.removeResource({ id: this.resource.id })
      done()
      this.emitChange()
    },
    download () {
      this.loadCustomerResourceFile({ id: this.resource.id })
    },
    emitChange () {
      this.$emit('changed')
    }
  }
}
</script>


<i18n>
en:
  removeConfirm: "Are you sure you want to delete this document?"
et:
  removeConfirm: "Are you sure you want to delete this document?"
ru:
  removeConfirm: "Are you sure you want to delete this document?"
</i18n>
