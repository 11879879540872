<template lang = "pug">
fi-modal.customer-communication-new-modal(
  v-model = 'showModal'
  :title  = '$t("title")'
  size    = 'lg'
  ref     = 'modal'
  lazy
  @shown  = 'resetData'
  @hide   = 'onHide'
)
  form.form-horizontal(@submit.prevent = 'onSubmit')
    fi-form-field(
      :label     = '$t("channel")'
      label-cols = 2
    )
      fi-select(
        v-model.number = 'messageData.channelId'
        :options       = 'optionsFromClassifier("documentDeliveryChannels")'
        required
        sm
      )
    fi-form-field(
      :label     = '$t("to")'
      label-cols = 2
    )
      fi-select(
        v-if           = 'isEmailChannel'
        v-model.number = 'messageData.customerEmail'
        :options       = 'customerEmailOptions'
        required
        sm
      )
      fi-select(
        v-else
        v-model  = 'messageData.customerPhoneNumber'
        :options = 'customerPhoneOptions'
        required
        sm
      )
    fi-form-field(
      :label     = '$t("priority")'
      label-cols = 2
    )
      fi-select(
        v-model.number = 'messageData.priorityId'
        :options       = 'optionsFromClassifier("priorities")'
        sm
      )
    fi-form-field(
      v-if       = 'messageData.channelId === 2'
      :label     = '$t("subject")'
      label-cols = 2
    )
      input.form-control.form-control-sm(
        v-model = 'messageData.subject'
        type    = 'text'
      )
    fi-form-field(
      v-if       = 'messageData.channelId !== 3'
      :label     = '$t("text")'
      label-cols = 2
    )
      fi-rich-text-editor(v-model = 'messageData.text'
                          v-if    = 'messageData.channelId !== 1')
      textarea.form-control.form-control-sm.from-input-to-form(v-model = 'messageData.text'
                                                               v-else)
    fi-form-field.mt-1(
      v-if       = 'messageData.channelId === 2'
      :label     = '$t("attachments")'
      label-cols = 2
    )
      fi-file-input(
        v-model = 'messageData.files'
        multiple
      )
  template(#modal-footer)
    button.btn.btn-primary(:disabled      = 'saving'
      @click.prevent = 'onSubmit')
      | {{ $t('send') }}
      i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
    button.btn.btn-secondary(:disabled = 'saving'
      @click.prevent = '$refs.modal.hide()') {{ $t('common:cancel') }}
</template>


<script>
import FiFileInput from '@/components/FiFileInput'
import FiFormField from '@/components/FiFormField'
import FiModal from '@/components/FiModal'
import FiRichTextEditor from '@/components/FiRichTextEditor'
import FiSelect from '@/components/FiSelect'
import { mapActions, mapGetters } from 'vuex'
import { findCustomerMainContact } from '@/helpers'

export default {
  name: 'customer-communication-new-modal',

  components: {
    FiRichTextEditor,
    FiModal,
    FiFileInput,
    FiFormField,
    FiSelect
  },

  props: {
    storeModule: {
      type: String,
      required: true
    },
    value: {
      type: Boolean,
      required: true
    },
    replyMessage: {
      type: Object,
      default: null
    },
    customer: {
      type: Object,
      default: null
    },
    direction: {
      type: String,
      required: true
    }
  },

  i18nOptions: {},

  data () {
    return {
      messageData: {
        customerEmail: null,
        customerPhoneNumber: null,
        channelId: '',
        priorityId: '',
        subject: null,
        text: null,
        files: [],
        inReplyTo: null
      }
    }
  },

  computed: {
    ...mapGetters('classifiers', ['optionsFromClassifier', 'classifierByName', 'classifierById']),
    showModal: {
      get () {
        return this.value
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    saving () {
      return this.$vueLoading.isLoading('customer:message:save')
    },
    isEmailChannel () {
      return /email/i.test(this.classifierById('documentDeliveryChannels', this.messageData.channelId).code)
    },
    customerEmailOptions () {
      return this.customer?.emails.map(({ value }) => value)
    },
    customerPhoneOptions () {
      return this.customer?.phoneNumbers.map(({ value }) => value)
    }
  },

  watch: {
    'messageData.channelId': 'fillCustomerContact'
  },

  methods: {
    ...mapActions({
      createMessage (dispatch, payload) {
        return dispatch(this.storeModule + '/createMessage', payload)
      }
    }),
    resetData () {
      Object.assign(this.$data, this.$options.data())
      this.messageData.channelId = this.classifierByName('documentDeliveryChannels', 'email')?.id
      this.messageData.priorityId = this.classifierByName('priorities', 'normal')?.id
      this.messageData.subject = this.replyMessage ? `RE: ${this.replyMessage.subject}` : ''
      this.messageData.inReplyTo = this.replyMessage?.messageId
      this.fillCustomerContact()
    },
    onHide (e) {
      if (this.saving) e.preventDefault()
      this.$emit('update:reply-message', null)
    },
    async onSubmit () {
      await this.createMessage({ messageData: this.messageData, direction: this.direction })
      this.$refs.modal.hide()
    },
    fillCustomerContact () {
      const { emails = [], phoneNumbers = [] } = this.customer ?? {}
      if (this.isEmailChannel) {
        this.messageData.customerEmail = findCustomerMainContact(emails)
        this.messageData.customerPhoneNumber = null
      } else {
        this.messageData.customerEmail = null
        this.messageData.customerPhoneNumber = findCustomerMainContact(phoneNumbers)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.from-input-to-form {
  height: 18em;
}
</style>

<i18n>
en:
  title:       "Send message"
  channel:     "Channel"
  to:          "To"
  priority:    "Priority"
  subject:     "Subject"
  text:        "Text"
  attachments: "Attachments"
  send:        "Send"
et:
  title:       "Send message"
  channel:     "Channel"
  to:          "To"
  priority:    "Priority"
  subject:     "Subject"
  text:        "Text"
  attachments: "Attachments"
  send:        "Send"
ru:
  title:       "Send message"
  channel:     "Channel"
  to:          "To"
  priority:    "Priority"
  subject:     "Subject"
  text:        "Text"
  attachments: "Attachments"
  send:        "Send"
</i18n>
