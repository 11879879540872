<template lang = "pug">
fi-modal.customer-document-form(
  ref     = 'modal'
  v-model = 'showModal'
  size    = 'md'
  :title  = 'title'
  lazy
  @hide   = 'onHide'
  @shown  = 'resetData'
)
  form.form(@submit.prevent = 'onSubmit')
    fi-data-field(
      v-if        = 'resource.id'
      :label      = '$t("file")'
      :label-cols = 3
    ) {{ resource.fileName || resource.s3File}}
    fi-form-field(
      v-else
      :label      = '$t("file")'
      :label-cols = 3
    )
      fi-file-input(v-model = 'resourceData.file')
    fi-form-field(
      :label      = '$t("category")'
      :state      = '!$v.resourceData.documentCategoryId.$error'
      :label-cols = 3
    )
      template(#error)
        .error-message(v-if = '!$v.resourceData.documentCategoryId.required') {{ $t('common:required') }}
      fi-select(
        v-model.number = 'resourceData.documentCategoryId'
        :options       = 'categories'
        :class         = '{ "is-invalid": $v.resourceData.documentCategoryId.$error }'
        required
        sm
      )
    fi-form-field(
      v-if        = '!isNew && !short && isContractRelation'
      :label      = '$t("contract")'
      :label-cols = 3
    )
      fi-multiselect(
        v-model      = 'documentRelationModel'
        :options     = 'contracts'
        group-values = 'items'
        group-label  = 'documentRelation'
        track-by     = 'documentRelationEntityId'
        label        = 'documentRelationEntityContractNumber'
      )
    fi-form-field(
      :label      = '$t("description")'
      :label-cols = 3
    )
      textarea.form-control(v-model = 'resourceData.comment')
    fi-form-field(
      :label      = '$t("visibleForPortal")'
      :label-cols = 3
    )
      fi-switch(v-model = 'resourceData.visibleForPortal')
  template(#modal-footer)
    button.btn.btn-primary(
      :disabled      = 'saving'
      @click.prevent = 'onSubmit'
    ) {{ $t('common:save') }}
      i.fa.fa-spinner.fa-pulse(v-if = 'saving')
    button.btn.btn-secondary(
      :disabled      = 'saving'
      @click.prevent = '$refs.modal.hide()'
    ) {{ $t('common:cancel') }}
</template>


<script>
import FiDataField from '@/components/FiDataField'
import FiFileInput from '@/components/FiFileInput'
import FiFormField from '@/components/FiFormField'
import FiModal from '@/components/FiModal'
import FiSelect from '@/components/FiSelect'
import { Resource } from '@/models'
import { validation } from '@/validation'
import FiSwitch from '@/components/FiSwitch'
import FiMultiselect from '@/components/FiMultiselect'

export default {
  name: 'customer-document-form',

  components: {
    FiMultiselect,
    FiSwitch,
    FiDataField,
    FiSelect,
    FiFormField,
    FiFileInput,
    FiModal
  },

  mixins: [validation],

  props: {
    value: {
      type: [Object, Boolean],
      required: true
    },
    resourceAction: {
      type: Function,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    resource: {
      type: Object,
      default: () => ({})
    },
    contracts: {
      type: Array,
      default: () => []
    },
    short: {
      type: Boolean
    }
  },

  i18nOptions: {},

  data () {
    return {
      resourceData: new Resource(this.resource)
    }
  },

  computed: {
    isNew () {
      return !this.resource.id
    },
    showModal: {
      get () {
        return Boolean(this.value)
      },
      set (newValue) {
        this.$emit('input', newValue)
      }
    },
    saving () {
      return this.$vueLoading.isLoading('resource:save')
    },
    title () {
      if (this.isNew) {
        return this.$t('upload')
      }
      return this.$t('edit')
    },
    documentRelationModel: {
      get () {
        const { documentRelationEntityId, documentRelationCode, documentRelationEntityContractNumber } = this.resourceData
        return {
          documentRelationCode,
          documentRelationEntityContractNumber,
          documentRelationEntityId
        }
      },
      set (relation) {
        this.resourceData = {
          ...this.resourceData,
          ...relation
        }
      }
    },
    isContractRelation () {
      return /^(?!(message|customer))/i.test(this.resource.documentRelationCode)
    }
  },

  validations: {
    resourceData: Resource.validation
  },

  created () {
    this.resetData()
  },

  methods: {
    resetData () {
      this.$v.$reset()
      Object.assign(this.$data, this.$options.data.apply(this))
      this.resourceData = new Resource(this.resource)
    },
    onHide (e) {
      if (this.saving) e.preventDefault()
    },
    async onSubmit () {
      if (this.validate()) {
        const { documentRelationEntityContractNumber, ...resourceData } = this.resourceData
        await this.resourceAction({ resourceId: this.resource.id, resourceData })
        this.$refs.modal.hide()
      }
    }
  }
}
</script>


<i18n>
en:
  edit:             "Edit document"
  upload:           "Upload document"
  file:             "File"
  category:         "Category"
  contract:         "Contract"
  description:      "Description"
  visibleForPortal: "Visible to customer"
et:
  edit:             "Edit document"
  upload:           "Upload document"
  file:             "File"
  category:         "Category"
  contract:         "Contract"
  description:      "Description"
  visibleForPortal: "Visible to customer"
ru:
  edit:             "Edit document"
  upload:           "Upload document"
  file:             "File"
  category:         "Category"
  contract:         "Contract"
  description:      "Description"
  visibleForPortal: "Visible to customer"
</i18n>
