<template lang="pug">
div
  fi-multiselect.fi-customer-select(
    v-model          = 'customerModel'
    :options         = 'customerOptions'
    :search-callback = 'findCustomersScopedBySector'
    :loading         = '$vueLoading.isLoading("systemCustomers:fetch")'
    v-bind           = '$attrs'
  )
  button.cross(
    v-if           = 'flag && customerModel'
    @click.prevent = '$emit("input", null)'
  ) {{ '×' }}
</template>

<script>
import FiMultiselect from '@/components/FiMultiselect'
import api from '@/api'
import { customerName, findCustomerMainContact } from '@/helpers'

export default {
  name: 'fi-customer-select',

  components: { FiMultiselect },

  inheritAttrs: false,

  model: {
    prop: 'customer'
  },

  props: {
    sectorId: {
      type: [Number, String],
      default: undefined
    },
    segmentId: {
      type: [Number, String],
      default: undefined
    },
    customer: {
      type: Object,
      default: null
    },
    flag: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    customerOptions: []
  }),

  computed: {
    customerModel: {
      get () {
        if (this.customer) {
          return this.parseCustomer(this.customer)
        } else {
          return null
        }
      },
      async set (selectedCustomer) {
        if (!selectedCustomer) {
          this.$emit('input', null)
        } else {
          const { data: customer } = await api.getCustomer({ id: selectedCustomer?.value })
          this.$emit('input', customer)
        }
      }
    }
  },

  created () {
    if (this.customers) {
      this.customerOptions = this.customers
    }
  },

  methods: {
    async findCustomersScopedBySector ({ query }) {
      if (!query) return
      this.$vueLoading.startLoading('systemCustomers:fetch')
      try {
        const { data: { content: customers } } = await api.getCustomers({ filters: { criteria: query, sectorId: this.sectorId, segmentId: this.segmentId } })

        this.customerOptions = customers.map(this.parseCustomer)
        return this.customerOptions[0]
      } finally {
        this.$vueLoading.endLoading('systemCustomers:fetch')
      }
    },
    parseCustomer (customer) {
      if (!customer) return customer
      return {
        value: customer.id,
        text: `${customerName(customer)} (${customer.idCode})`,
        sectorId: customer.sectorId,
        segmentId: customer.segmentId,
        phone: findCustomerMainContact(customer.phoneNumbers),
        email: findCustomerMainContact(customer.emails)
      }
    }
  }
}
</script>

<style lang='scss'>
div {
  position: relative;

  .cross {
    position: absolute;
    width: fit-content;
    height: fit-content;
    background-color: transparent;
    padding: 0;
    border: none;
    outline: none;
    left: 90%;
    top: 50%;
    transform: translate(0, -50%);
    font-weight: bold;
  }
}
</style>
