import { render, staticRenderFns } from "./CustomerCommunicationItem.vue?vue&type=template&id=1d8cd9fb&scoped=true&lang=pug&"
import script from "./CustomerCommunicationItem.vue?vue&type=script&lang=js&"
export * from "./CustomerCommunicationItem.vue?vue&type=script&lang=js&"
import style0 from "./CustomerCommunicationItem.vue?vue&type=style&index=0&id=1d8cd9fb&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d8cd9fb",
  null
  
)

export default component.exports