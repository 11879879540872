<template lang="pug">
.card.customer-comments-view
  b-tabs(card)
    b-tab(:title = '$t("tasks")'
          lazy)
      CustomerTasksList(:load-tasks  = 'loadComments'
                        :tasks       = 'comments'
                        :tasks-pages = 'commentsPages'
                        :loader      = 'loader')
    b-tab(:title = '$t("notes")'
          lazy)
      CustomerNotesList(:load-notes  = 'loadComments'
                        :notes       = 'comments'
                        :notes-pages = 'commentsPages'
                        :loader      = 'loader')
</template>


<script>
import { mapState, mapActions } from 'vuex'
import CustomerNotesList from '@/views/customers/CustomerNotesList'
import CustomerTasksList from '@/views/customers/CustomerTasksList'

export default {
  name: 'customer-comments-view',

  components: {
    CustomerNotesList,
    CustomerTasksList
  },

  props: {
    commentsState: {
      type: String,
      required: true
    }
  },

  inject: ['storeModule'],

  i18nOptions: {},

  computed: {
    ...mapState({
      comments (state, getters) {
        return getters[`${this.storeModule}/sortedComments`]
      },
      commentsPages (state) {
        return state[`${this.storeModule}`].commentsPages
      }
    }),
    loader () {
      return `${this.storeModule}:comments:fetch`
    }
  },

  methods: {
    ...mapActions({
      loadComments (dispatch, payload) {
        return dispatch(`${this.storeModule}/loadComments`, payload)
      }
    })
  }
}
</script>


<i18n>
en:
  tasks:    "Tasks"
  notes:    "Notes"
et:
  tasks:    "Tasks"
  notes:    "Notes"
ru:
  tasks:    "Tasks"
  notes:    "Notes"
</i18n>
