<template lang="pug">
.fi-custom-field
  fi-form-field(
    v-if   = 'editing'
    :label = 'options.name || options.description'
    :required = 'required'
  )
    .form-row
      .col
        component(
          :is       = 'inputComponent.component'
          :class    = '{ ...inputComponent.class, "is-invalid": (required && !inputComponent.format($attrs.value)) }'
          :on-text  = '"yes"'
          :off-text = '"no"'
          :required = 'String(required)'
          v-bind    = 'componentDate'
          @input    = 'customFieldHandler'
        )
      .col-auto
        button.btn.btn-sm.btn-link.text-danger.px-1(
          v-if = '!required'
          @click.prevent = '$emit("remove")'
        )
          i.far.fa-trash-alt.fa-lg
        button.btn.btn-sm.btn-link.text-danger.px-1.hidden(
          v-else

          @click.prevent = '$emit("remove")'
        )
          i.far.fa-trash-alt.fa-lg
  fi-data-field(
    v-else
    :label = 'options.name || options.description'
    :required = 'required'
  )
    div(:class = '{ "is-invalidDiv": (required && !inputComponent.format($attrs.value)) }') {{ getClassifierCode || inputComponent.format($attrs.value) }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiDataField from '@/components/FiDataField'
import { sortClassifiers } from '@/helpers'

const FiDatepicker = () => import('@/components/FiDatepicker')
const FiSelect = () => import('@/components/FiSelect')
const FiInput = () => import('@/components/FiInput')
const FiSwitch = () => import('@/components/FiSwitch')

const INPUTS = {
  TEXT: {
    component: FiInput,
    params: {
      type: 'text'
    }
  },
  NUMBER: {
    component: FiInput,
    params: {
      type: 'number'
    }
  },
  DATE: {
    component: FiDatepicker
  },
  SELECT: {
    component: FiSelect
  },
  BOOLEAN: {
    component: FiSwitch,
    params: {
      type: 'boolean'
    }
  }
}

export default {
  name: 'fi-custom-field-item',

  components: { FiDataField, FiFormField },

  inheritAttrs: false,

  props: {
    options: {
      type: Object,
      required: true
    },
    editing: {
      type: Boolean
    },
    required: {
      type: Boolean
    }
  },

  computed: {
    getClassifierCode () {
      return this.options?.defaultValues?.find(({ classifierValue }) => classifierValue === this.$attrs.value)?.classifierCode
    },
    componentDate () {
      const data = { ...this.$attrs, ...this.inputComponent.params }
      if (data.type === 'boolean') {
        if (data.value === undefined) {
          this.customFieldHandler(true)
        }
        if (data.value === ('false' || 'no')) {
          data.value = false
        } else {
          data.value = true
        }
      }
      return data
    },
    inputComponent () {
      let options = INPUTS[this.options.type]
      if (this.options?.defaultValues?.length > 1) {
        options = {
          ...INPUTS.SELECT,
          params: {
            options: sortClassifiers(this.options.defaultValues.filter(({ isEnabled }) => isEnabled)
              .map(({ classifierValue, classifierCode }) => ({ text: classifierCode, value: classifierValue })))
          }
        }
      }

      switch (this.options.type) {
        case 'DATE':
          return {
            ...options,
            format: (value) => this.$t('common:formatDate', { value })
          }
        case 'BOOLEAN':
          return {
            ...options,
            format: (value) => {
              return value === 'true' ? 'yes' : 'no'
            }
          }
        default:
          return {
            ...options,
            format: (value) => value
          }
      }
    }
  },

  methods: {
    customFieldHandler (data) {
      this.$emit('input', data)
    }
  }
}
</script>

<style lang="scss">
.is-invalidDiv {
  width: 305px;
  height: 21px;
  border: 1px solid #ff5454;
}
.hidden {
  visibility: hidden;
}
</style>
