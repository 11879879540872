<template lang="pug">
.customer-communication.animated.fadeIn.card
  b-tabs(
    v-model = 'currentTab'
    card
  )
    template(#tabs-end)
      li.nav-item.align-self-center.ml-auto
        button.btn.btn-link(@click.prevent = 'showNewCommunicationModal = true') {{ $t('sendMessage') }}
    b-tab(
      :title = '$t("inbox")'
      lazy
      no-body
    )
      customer-communication-list(
        :messages-state       = 'messagesState'
        :messages-pages-state = 'messagesPagesState'
        :direction             = 'tabNames[0]'
        :store-module         = 'storeModule'
        @reply                = 'reply'
      )
    b-tab(
      :title = '$t("sent")'
      lazy
      no-body
    )
      customer-communication-list(
        :messages-state       = 'messagesState'
        :messages-pages-state = 'messagesPagesState'
        :direction             = 'tabNames[1]'
        :store-module         = 'storeModule'
        @reply                = 'reply'
      )
  customer-communication-new-modal(
    v-model             = 'showNewCommunicationModal'
    :store-module       = 'storeModule'
    :reply-message.sync = 'replyMessage'
    :customer           = 'customer'
    :direction          = 'tabNames[currentTab]'
  )
</template>


<script>
import CustomerCommunicationList from '@/views/customers/CustomerCommunicationList'
import CustomerCommunicationNewModal from './CustomerCommunicationNewModal'
import { mapState } from 'vuex'

const tabNames = ['IN', 'OUT']

export default {
  name: 'customer-communication',

  components: {
    CustomerCommunicationList,
    CustomerCommunicationNewModal
  },

  inject: {
    storeModule: { default: 'customers' }
  },

  props: {
    messagesState: {
      type: String,
      required: true
    },
    messagesPagesState: {
      type: String,
      required: true
    },
    short: {
      type: Boolean,
      default: false
    },
    customerState: {
      type: String,
      required: true
    }
  },

  i18nOptions: {},

  data: () => ({
    currentTab: 0,
    tabNames,
    replyMessage: null,
    showNewCommunicationModal: false
  }),

  computed: {
    ...mapState({
      customer (state) {
        return state[this.storeModule][this.customerState]
      }
    })
  },

  methods: {
    reply (replyMessage) {
      this.replyMessage = replyMessage
      this.showNewCommunicationModal = true
    }
  }
}
</script>


<i18n>
en:
  inbox:       "Inbox"
  sent:        "Sent"
  sendMessage: "Send message"
et:
  inbox:       "Inbox"
  sent:        "Sent"
  sendMessage: "Send message"
ru:
  inbox:       "Inbox"
  sent:        "Sent"
  sendMessage: "Send message"
</i18n>


<style scoped lang="scss">
.customer-communication /deep/ .tab-content {
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
}
</style>
